import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import LocalizedLink from '../LocalizedLink';


export const PostItemLink = styled(LocalizedLink)`
  text-decoration: none;
  display: block;
  margin: var(--space-lg) 0;
`;

export const PostItemWrapper = styled.section`
  display: flex;
  ${media.greaterThan('medium')`
    align-items: center;;
  `}
`;


export const PostItemImg = styled(Img)`
  width: 64px;
  ${media.greaterThan('small')`
    width: 122px;
  `}    
  ${media.greaterThan('medium')`
    width: 244px;
  `}  
`;

export const PostItemInfo = styled.div`
  margin-left: var(--space-sm);
  ${media.greaterThan('small')`
    margin-left: var(--space);
  `}    
  ${media.greaterThan('medium')`
    margin-left: var(--space-lg);
  `}    
`;

export const PostItemDate = styled.time`
  display:block;
  color: var(--text-light);
  font-size: 1.4rem;
  ${media.greaterThan('small')`
    margin-bottom: var(--space-sm);
  `}  
  ${media.greaterThan('large')`
    font-size: 1.6rem;
  `}  
`;

export const PostItemTitle = styled.h3`
  font-weight: bold;
`;

export const PostItemDescription = styled.p`
  display:none;
  margin-top: var(--space-sm);
  color: var(--text-color);
  ${media.greaterThan('small')`
    display: block;
  `}    
`;

export const ReadMore = styled.div`
  ${media.greaterThan('small')`
    margin-top: var(--space-sm);
  `}   
`