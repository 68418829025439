import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink'

export const ListWrapper = styled.section`
  // margin-bottom: 20px;

  // ${media.greaterThan('small')`
  //       display: grid;
  //       grid-gap: 20px;
  //       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //   `}
  // ${media.greaterThan('large')`
  //       grid-template-columns: repeat(auto-fit, minmax(250px, calc(50% - 10px)));
  //   `}
`;

export const FeaturedWrapper = styled.section`
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  overflow: auto;
  grid-column-gap: var(--space);
  ${media.greaterThan('large')`
    grid-column-gap: var(--space-lg);
  `}
`

export const IntroWrapper = styled.section`
  background-color: var(--bg-light);
  border: 1px solid var(--bg-light);
`

export const MoreLinkBold = styled(LocalizedLink)`
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  margin-top: 1em;
`

export const SubTitle = styled.h2`
  line-height: 145%;
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 1em;
`;
